"use client";

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import { Suspense, lazy, useMemo } from "react";
import { ImageType } from "types";
import classNames from "lib/classNames";
import { CoverProps, coverTheme } from "hardcover-ui/theme/components/cover";
import RandomCover from "components/shared/RandomCover";
import { enlargeUrl, normalizeImageUrl } from "lib/imageTools";

const CoverStatus = lazy(() => import("./CoverStatus"));
const BookDetailsPopover = lazy(() =>
  import("features/BookDetailsPopover").then((module) => ({
    default: module.BookDetailsPopover,
  }))
);

const xSizes = {
  lg: 180,
  md: 100,
  sm: 67,
  xs: 50,
  xxs: 33,
};
const ySizes = {
  lg: Math.floor(xSizes.lg * 1.5),
  md: Math.floor(xSizes.md * 1.5),
  sm: Math.floor(xSizes.sm * 1.5),
  xs: Math.floor(xSizes.xs * 1.5),
  xxs: Math.floor(xSizes.xxs * 1.5),
};

function computeCoverSize({
  size,
  stretch,
  image,
}: {
  size: CoverProps["size"];
  stretch?: "x" | "y";
  image?: ImageType;
}) {
  // Test to force proportions
  // return {
  //   width: xSizes[size],
  //   height: ySizes[size],
  // };

  // No adjustments, placeholder image
  if (!stretch) {
    return {
      width: xSizes[size],
      height: ySizes[size],
    };
  }

  // Image, but no stretch, keep proportions of the image on the width
  if (image) {
    if (!stretch || stretch === "x") {
      const calculatedHeight = Math.round(
        Number(xSizes[size] * (1 / (image.width / image.height)))
      );
      return {
        width: xSizes[size],
        height: calculatedHeight,
      };
    }
    if (stretch === "y") {
      const calculatedWidth = Math.round(
        Number(ySizes[size] * (1 / (image.height / image.width)))
      );
      return {
        width: calculatedWidth,
        height: ySizes[size],
      };
    }
  }

  // Just in case. I don't think this is needed.
  return {
    width: xSizes[size],
    height: ySizes[size],
  };
}
export interface Props {
  id: number;
  image: ImageType;
  size?: CoverProps["size"];
  title?: string;
  variant?: CoverProps["variant"];
  settings?: CoverProps["settings"];
}

function Cover({ id, image, size, title, settings, variant }: Props) {
  const { height, width } = useMemo(
    () => computeCoverSize({ size, stretch: settings.stretch, image }),
    [size, settings?.stretch, image?.url]
  );

  const { url1x, url1Width, url2x, url2Width } = useMemo(() => {
    if (!width || !height) {
      return { url1x: null, url1Width: null, url2x: null, url2Width: null };
    }

    // Use larger images than expected for mobile
    const w1 = Math.round(width);
    const w2 = Math.round(width * 1.5);

    const sourceUrl = normalizeImageUrl(image.url);

    // Use larger image than is shown due to pixel ratio
    const u1 = enlargeUrl({
      url: sourceUrl,
      width: w1,
      height: Math.round(height),
    });
    const u2 = enlargeUrl({
      url: sourceUrl,
      width: w2,
      height: Math.round(height * 1.5),
    });

    return {
      url1Width: w1,
      url1x: u1,
      url2Width: w2,
      url2x: u2,
    };
  }, [width, height, image?.url]);

  // const staticColor = useMemo(
  //   () => placeholderForColor(image?.color),
  //   [image?.url]
  // );

  // const placeholder = useMemo(() => {
  //   const p: any = {};
  //   if (height > 40 && width > 40) {
  //     p.placeholder = "blur";
  //     p.blurDataURL = staticColor;
  //   }
  //   return p;
  // }, [height, width]);

  const finalClassName = classNames(
    coverTheme.variant[variant],
    coverTheme.size?.[size]
  );

  return (
    <div className={`${finalClassName} relative`}>
      {settings.showStatus && (
        <Suspense>
          <CoverStatus bookId={id} size={size} />
        </Suspense>
      )}
      {settings.details && (
        <Suspense>
          <BookDetailsPopover bookId={id} size={size} />
        </Suspense>
      )}
      <img
        src={url1x}
        srcSet={`${url1x} ${url1Width}w, ${url2x} ${url2Width}w`}
        alt={title}
        width={width}
        height={height}
        loading={settings.eager ? "eager" : "lazy"}
        // {...placeholder}
      />
    </div>
  );
}

type PlaceholderProps = {
  id: number;
  size: CoverProps["size"];
  title: string;
  variant: CoverProps["variant"];
  settings?: CoverProps["settings"];
};
export function PlaceholderCover({
  id,
  size,
  settings = {},
  title,
  variant,
}: PlaceholderProps) {
  const { height, width } = useMemo(
    () => computeCoverSize({ size, stretch: settings?.stretch }),
    [size, settings?.stretch]
  );

  const finalClassName = classNames(
    coverTheme.variant[variant],
    coverTheme.size?.[size]
  );

  return (
    <div
      className={finalClassName}
      style={{ maxWidth: width, maxHeight: height }}
    >
      {settings.showStatus && (
        <Suspense>
          <CoverStatus bookId={id} size={size} />
        </Suspense>
      )}
      {settings.details && (
        <Suspense>
          <BookDetailsPopover bookId={id} size={size} />
        </Suspense>
      )}
      <RandomCover id={id} loading={settings.eager ? "eager" : "lazy"} />
      {size !== "xs" && (
        <p
          className={classNames(
            "text-white absolute top-0 flex items-end h-full w-full max-w-full text-left text-ellipsis font-serif break-words z-10",
            size === "sm" ? "text-xs p-1 leading-3" : "",
            size === "md" ? "text-lg p-1 leading-5" : "",
            size === "lg" ? "text-3xl p-4 leading-6" : ""
          )}
        >
          {title ? title.split(":")[0] : ""}
        </p>
      )}
    </div>
  );
}

export default function CoverImage({
  id,
  image,
  size = "md",
  title,
  variant = "none",
  settings = {},
}: Props) {
  if (image?.url) {
    return (
      <Cover
        id={id}
        image={image}
        size={size}
        title={title}
        variant={variant}
        settings={settings}
      />
    );
  }
  return (
    <PlaceholderCover
      id={id}
      size={size}
      title={title}
      settings={settings}
      variant={variant}
    />
  );
}
