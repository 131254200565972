import { cdnUrl } from "lib/imageTools";

const covers = [
  cdnUrl("/static/covers/cover1.png"),
  cdnUrl("/static/covers/cover2.png"),
  cdnUrl("/static/covers/cover3.png"),
  cdnUrl("/static/covers/cover4.png"),
  cdnUrl("/static/covers/cover5.png"),
  cdnUrl("/static/covers/cover6.png"),
  cdnUrl("/static/covers/cover7.png"),
  cdnUrl("/static/covers/cover8.png"),
  cdnUrl("/static/covers/cover9.png"),
];

const coversLength = covers.length;

interface Props {
  id?: number;
  loading?: "lazy" | "eager";
}

const RandomCover = ({ id = 0, loading = "lazy" }: Props) => {
  const index = id % coversLength;
  const coverUrl = covers[index];
  return (
    <img
      src={coverUrl}
      alt={`Cover ${index}`}
      width={200}
      height={300}
      loading={loading}
    />
  );
};

export default RandomCover;
