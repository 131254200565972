"use client";

import Button from "hardcover-ui/components/Button";
import Icon from "hardcover-ui/components/Icon";
import { ButtonThemeProps } from "hardcover-ui/theme/components/button";
import partyHorn from "icons/solid/party-horn.svg";

export default function YearInBooksLink({
  size = "md",
  variant = "primary",
}: {
  size?: ButtonThemeProps["size"];
  variant?: ButtonThemeProps["variant"];
}) {
  return (
    <Button
      variant={variant}
      className="flex-none"
      size={size}
      as={{
        element: "link",
        props: {
          href: `/2024`,
        },
      }}
    >
      <Icon icon={partyHorn} size="md" />
      <span className="hidden xl:inline">Year in Books</span>
    </Button>
  );
}
